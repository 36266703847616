import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Dolphins = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Dolphins | HB Privileged',
      title: 'SWIM WITH DOLPHINS AT AQUAVENTURAS',
      subtitle: 'TOUR OVERVIEW',
      text: "Dolphinariums in Mexico are not likely to operate for much longer so you are right on time to enjoy this majestic mammals. Aquaventuras is an international company with years of experience that work very hard to preserve and care for these beautiful animals. With all protocols ensuring the safety of dolphins and humans, you can swim, jump and play together! Aquaventuras is at a water park, and the dolphin programms include the entrance to the park. Glide along the waterslides, relax in the slow river or go for the adventure zip lining across the park or climbing the rock wall. Swimming with the dolphins at Aquaventuras Waterpark is definetly a once in a lifetime experience!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Dolphins | HB Privileged',
      title: 'NADO CON DELFINES EN AQUAVENTURAS',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Los delfinarios probablemente no operarán por mucho tiempo más en México así que estás justo a tiempo para disfrutar a estos majestuosos mamíferos. Aquaventuras es una compañía internacional con años de experiencia que trabaja muy duro para preservar y cuidar a estos bellos animales. Con todos los protocolos para garantizar la seguridad de delfines y humanos, pueden nadar, brincar y jugar juntos! Aquaventuras está en un parque acuático y los programas de delfines incluyen la entrada al parque. Deslízate por sus toboganes, relájate en el río lento o ve a la aventura en la tirolesa a través del parque o escalando la pared de rocas. Ndar con los delfines en el parque acuático de Aquaventuras es definitivamente una experiencia única en la vida!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/aquaventura1.jpeg`,
    `${BASE_URL}/tours/aquaventura2.jpg`,
    `${BASE_URL}/tours/aquaventura3.jpg`,
    `${BASE_URL}/tours/aquaventura4.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/delfin.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Dolphins;